(function($) {

    var redirectTimeout,
        redirectTimeoutMinutes = 3;

    function setRedirectTimeout() {
        // Log
        //console.log( 'Redirect Set!' );
        // Colocar timeout
        redirectTimeout = setTimeout(function() {
            // Verificar se faz redirect ou não
            if( rewardsAjaxObject.hasOwnProperty( 'redirectOn' ) && rewardsAjaxObject.redirectOn === '1' ) {
                // Fazer redirect
                $( location ).attr( 'href', rewardsAjaxObject.redirectLink );
            }
        }, ( redirectTimeoutMinutes * 60 ) * 1000 );
    }

    function clearRedirectTimeout() {
        // Log
        //console.log( 'Redirect Cleared!' );
        // Clear timeout
        clearTimeout( redirectTimeout );
    }

    function resetRedirectTimeout() {
        // Log
        //console.log( 'Redirect Reseted!' );
        // Clear redirect
        clearRedirectTimeout();
        // Set redirect
        setRedirectTimeout();
    }
    
    // Colocar timeout de ínicio
    $( document ).ready( setRedirectTimeout );

    /**
     * Disparar código de acordo com a class do body
     */

    var Rewards = {
        'common': {
            init: function() {

                /**
                 * Redirects na interação com os input 
                 */

                $( 'input, textarea, button' ).focus( resetRedirectTimeout );
                $( 'input, textarea, button' ).blur( resetRedirectTimeout );

                /**
                 * Revelar Objetos
                 */

                // Criar nova instância para revelar objetos
                var revealElements = new WOW({
                    boxClass: 'reveal',
                    animateClass: 'reveal-animated',
                    live: true,
                    callback: function( el ) {
                        // Quando a animação acabar
                        $( el ).one( 'webkitAnimationEnd oanimationend msAnimationEnd animationend', function ( event ) {
                            // Colocamos uma class
                            $( this ).addClass( 'reveal-active' );
                        });
                    }
                });

                // Iniciar a instância
                revealElements.init();

                /**
                 * Forçar telefone no input
                 */

                // Seleccionar input do telefone
                var $phoneInput = $( '.phone-input input' );

                // Se houver input de telefone
                if( $phoneInput.length > 0 ) {
                    // Quando se escrever no input do telefone
                    $phoneInput.keyup(function( event ) {
                        if (/[^0-9 \+]/g.test(this.value)) {
                            // Remover não-digitos do input
                            this.value = this.value.replace(/[^0-9 \+]/g, '');
                        }
                    });
                }
                
                /**
                 * Forçar a abertura do formulário
                 */

                // Quando o formulário for fechado
                $( '#main-form' ).on( 'hidden.bs.collapse', function( event ) {
                    // Colocar um pequeno timeout
                    setTimeout(function() {
                        // Abrir formulário de novo
                        $( '#main-form' ).collapse( 'show' );
                    }, 250);
                });

                // Quando o botão for clicado
                $( '.form-option' ).on( 'click', function( event ) {
                    // Dar cache nos pais
                    var $parent = $( this ).parent(),
                        $topParent = $( this ).parent().parent();
                    // Buscar texto do botão
                    var optionText = $( this ).text();
                    // Inserir valor do botão no input
                    $( '#main-form .gfield.gform_hidden input' ).val( optionText );
                    $topParent.addClass( 'options-active' );
                    $( this ).addClass( 'active' );
                    $parent.siblings().find( '.form-option' ).removeClass( 'active' );
                    // Prevenir comportamento
                    event.preventDefault();
                });
                
            }
        }
    };

    var UTIL = {
        fire: function( func, funcname, args ) {
            var fire, namespace = Rewards;
            funcname = ( funcname === undefined ) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[ func ];
            fire = fire && typeof namespace[ func ][ funcname ] === 'function';
            if( fire ) {
                namespace[ func ][ funcname ]( args );
            }
        },
        loadEvents: function() {
            UTIL.fire( 'common' );
            $.each( document.body.className.replace(/-/g, '_').split(/\s+/), function( i, classnm ) {
                UTIL.fire( classnm );
                UTIL.fire( classnm, 'finalize' );
            });
            UTIL.fire( 'common', 'finalize' );
        }
    };

    $( document ).ready( UTIL.loadEvents );

})(jQuery);